// require('./bootstrap');
// require('./utilities');

// require('jquery');
// require('bootstrap');

require('bootstrap/js/dist/modal');
require('bootstrap/js/dist/tooltip');
// require('bootstrap-datepicker');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

window._ = require('lodash');
// window.$ = window.jQuery = require('jquery');

// import * as bootstrap from 'bootstrap';
// import bootstrap from 'bootstrap'
// You can specify which plugins you need
// import { Tooltip, Toast, Popover , Modal } from 'bootstrap';


bootstrap = require('bootstrap')